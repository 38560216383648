/* Full Contact Us Page */
.contactUsPage {
  background: linear-gradient(to bottom, #000000, #16213e);
}

/* First Section */
.firstSection {
  padding-top: 15px;
  height: 100vh; /* Full viewport height */
  background: url("../../assets/images/contact-us/contact-landing.webp")
    no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Second Section */
.secondSection {
  height: 100vh; /* Full viewport height */
  background: linear-gradient(to bottom, #000000, #16213e);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Map Section */
.mapSection {
  background: white;
  color: black;
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Information Section */
.contactInfo {
  text-align: right;
  width: 90%;
  max-width: 800px;
  line-height: 1.8;
  font-size: 1rem;
  color: white;
}

.contactInfo p {
  margin: 10px 0;
}

.contactInfo strong {
  font-weight: bold;
}
