/* Full About Us Page */
.aboutUsPage {
  width: 100%;
  font-family: "Arial", sans-serif;
  overflow-x: hidden;
}

/* First Section (Top Section) */
.firstSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

/* Title Container */
.titleContainer {
  text-align: center;
  margin-top: 200px; /* Updated margin-top */
}

.titleContainer h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

/* Separator Line */
.separator {
  width: 100px; /* Updated width */
  height: 2px;
  margin: 10px auto; /* Centered */
  background: white;
  border: none;
}

/* Scroll Down Section */
.scrollDown {
  text-align: center;
  position: relative;
  margin-bottom: 40px;
  transition: opacity 0.3s ease-in-out;
}

.scrollDown.hidden {
  opacity: 0; /* Hide the button when scrolled down */
  pointer-events: none; /* Disable any interaction */
}

.curvedText {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 100px;
  margin-bottom: -45px;
}

.curvedText text {
  font-size: 14px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: white;
}

.scrollIcon {
  margin: 0 auto;
  width: 24px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollIcon span {
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  animation: scroll 1.5s infinite ease-in-out;
}

/* Visibility Animation for Scroll Button */
@keyframes scroll {
  0% {
    transform: translateY(-8px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(8px);
    opacity: 0;
  }
}

/* Second Section */
.secondSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Overlay for Second Section */
.overlay {
  position: relative;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay effect */
  padding: 30px 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
}

/* Logo Styling */
.logo {
  width: 300px;
  height: auto;
  margin: 0 auto 20px auto;
}

/* Description Text */
.description {
  font-size: 1rem;
  line-height: 1.8;
  direction: rtl; /* For Farsi text */
  margin-top: 20px;
}

/* Third Section (Team Section) */
.thirdSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px 20px;
  background: #101820;
}

/* Person Card Styles */
.personCard {
  opacity: 0;
  transform: translateX(
    -50px
  ); /* Initially, shift left for even-indexed cards */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.personCard.visible {
  opacity: 1;
  transform: translateX(0); /* Animate to original position */
}

/* Person Card Styles for Odd-Indexed Cards */
.personCard.right {
  transform: translateX(
    50px
  ); /* Initially, shift right for odd-indexed cards */
}

.personCard.right.visible {
  transform: translateX(0); /* Animate to original position */
}
