/* Individual Slide Styling */
.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
/* .prevSlide {
  transform: translateX(100%);
  transition: none;
}
.nextSlide {
  transform: translateX(-100%);
} */

.slide.active {
  /* transform: translateX(0%); */
  opacity: 1;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageGradient {
  /* Gradient overlay */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(at center center, #00000000 0%, #000000 100%);
  pointer-events: none; /* Prevent the overlay from blocking other interactions */
}

/* Hero Text */
.heroText {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  width: 90%;
}

/* Navigation Buttons */
.navLeft,
.navRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
}

/* Position for Right Navigation Button */
.navRight {
  left: 20px; /* Now associated with prevSlide */
}

/* Position for Left Navigation Button */
.navLeft {
  right: 20px; /* Now associated with nextSlide */
}
/* Indicators */
.indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.indicator {
  width: 15px;
  height: 15px;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
}

.indicator.activeIndicator {
  background: yellow;
}
