/* Scroll Icon Container */
.scrollIconContainer {
  text-align: center;
  position: relative;
  margin-bottom: 10px; /* Adjust margin to bring it lower */
  width: 100%;
  height: 150px; /* Ensure enough height for the curved text */
}

/* Curved Text Styling */
.curvedText {
  display: block;
  margin: 0 auto;
  width: 200px; /* Matches the SVG's width */
  height: 100px; /* Matches the SVG's height */
  margin-bottom: -45px;
}

.curvedText text {
  font-size: 14px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: white; /* Make sure text is visible */
}

/* Scroll Icon */
.scrollIcon {
  margin: 0 auto;
  width: 24px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollIcon span {
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  animation: scroll 1.5s infinite ease-in-out;
}

/* Scroll Animation */
@keyframes scroll {
  0% {
    transform: translateY(-8px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(8px);
    opacity: 0;
  }
}
