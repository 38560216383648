/* Full Services Page */
.servicesPage {
  font-family: "Vazir", Arial, sans-serif;
  color: white;
  background: radial-gradient(at center center, #00000000 0%, #000000 100%),
    url("../../assets/images/services/services-landing.webp");
}

/* First Section */
.firstSection {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Second Section */
.secondSection {
  background: #101820;
  text-align: center;
  padding: 40px 20px;
}

.videoPlayer {
  width: 90%;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Third Section */
.thirdSection {
  background: #000000;
  text-align: center;
  padding: 40px 20px;
}

.image {
  width: 90%;
  max-width: 800px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 15px;
}

.text {
  font-size: 1rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 10px;
  color: #ccc;
}
